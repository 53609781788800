<template>
  <div>
    <AppTopBar />
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
        <floating-btn />
      </div>
      <AppFooter />
    </div>
    <transition name="layout-mask">
      <div v-if="mobileMenuActive" class="layout-mask p-component-overlay" />
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue"
import AppFooter from "./AppFooter.vue"
import FloatingBtn from "@/components/FloatingBtn.vue"

export default {
  components: {
    AppTopBar: AppTopBar,
    AppFooter: AppFooter,
    FloatingBtn
  },
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false
    }
  },

  methods: {
    isDesktop() {
      return window.innerWidth >= 992
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive
        else if (this.layoutMode === "overlay") return this.overlayMenuActive
      }

      return true
    }
  }
}
</script>

<style lang="scss">
@import "./App.scss";
</style>

<template>
  <div>
    <a href="https://api.whatsapp.com/send?phone=+959402546908&text=Hello" target="_blank">
      <Button icon="pi pi-whatsapp"
        class="p-button-rounded p-button-raised p-button-lg bg-primary-reverse z-5 float fixed"></Button></a>
  </div>
</template>

<script>
import Button from "primevue/button"

export default {
  components: { Button }
}
</script>

<style>
.float {
  bottom: 15px;
  right: 15px;
}
</style>

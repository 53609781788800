import "@/plugins/global-css"

import { app } from "@/plugins/main-app"
import "@/plugins/global-components"
import "@/plugins/i18n"
import "@/plugins/primevue"
import "@/plugins/router"
import "@/plugins/toast-service"
import "@/plugins/theme"

app.mount("#app")

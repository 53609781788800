<template>
  <div class="layout-topbar bg-primary">
    <router-link to="/" class="layout-topbar-logo">
      <div class="flex flex-column align-items-center justify-content-center">
        <img alt="Logo" :src="topbarImage()" class="w-8rem" />
        <span class="block text-sm text-color-secondary text-900">Moe Htet Tun Agricultural and Trading</span>
      </div>
      <!-- <span class="text-color-secondary text-3xl text-900">Moe Htet Tun</span> -->
    </router-link>
    <button v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true
      }" class="p-link layout-topbar-menu-button layout-topbar-button">
      <i class="pi pi-ellipsis-v" />
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <router-link to="/about-us">
          <Button type="button" label="About Us" style="width: auto" class="p-button-text" />
        </router-link>
      </li>
      <li>
        <router-link to="/products-knowledge">
          <Button type="button" label="Products Knowledge" style="width: auto" class="p-button-text" />
        </router-link>
      </li>
      <li>
        <router-link to="/contact-us">
          <Button type="button" label="Contact Us" style="width: auto" class="p-button-text" />
        </router-link>
      </li>
      <li>
        <Menu ref="productMenu" :model="productMenuItems" :popup="true" />
        <Button type="button" label="Products" class="p-button-text mr-2 mb-2" icon="pi pi-angle-down" iconPos="right"
          @click="toggleProductMenu" />
      </li>
    </ul>
  </div>
</template>

<script>
import Button from "primevue/button"
import Menu from "primevue/menu"
import { defineComponent, ref } from "vue"
export default defineComponent({
  components: { Button, Menu },
  setup() {
    const productMenuItems = ref([
      {
        label: "All Products",
        to: "/products-knowledge"
      },
      {
        label: "Green Mung Bean",
        to: "/product-detail/green-mung-bean"
      },
      {
        label: "Ground Nuts",
        to: "/product-detail/ground-nuts-bean"
      },
      {
        label: "Butter Bean",
        to: "/product-detail/butter-bean"
      },
      {
        label: "Lablab Bean",
        to: "/product-detail/lablab-bean"
      },
      {
        label: "Bamboo Bean",
        to: "/product-detail/bamboo-bean"
      },
      {
        label: "Red Flat Bean",
        to: "/product-detail/red-flat-bean"
      },
      {
        label: "Red Bamboo Bean",
        to: "/product-detail/red-bamboo-bean"
      }
    ])

    return {
      productMenuItems
    }
  },
  methods: {
    onMenuToggle(event) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("menu-toggle", event)
    },
    onTopbarMenuToggle(event) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("topbar-menu-toggle", event)
    },
    topbarImage() {
      return require("@/assets/images/MHT.svg")
    },

    toggleProductMenu(event) {
      this.$refs.productMenu.toggle(event)
    }
  }
})
</script>
<style>
.layout-topbar-menu .p-button-text {
  color: #404040;
}

.router-link-active .p-button-text {
  color: #295f2d;
}
</style>

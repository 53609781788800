const dashboardRoutes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/modules/dashboard/Home.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/about-us",
    name: "about",
    component: () => import("@/modules/dashboard/About.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () => import("@/modules/dashboard/Contact.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/products-knowledge",
    name: "products-knowledge",
    component: () =>
      import("@/modules/dashboard/productsKnowledge/ProductsKnowledge.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-knowledge/:id",
    name: "product-knowledge",
    component: () =>
      import("@/modules/dashboard/productsKnowledge/ProductKnowledge.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/green-mung-bean",
    name: "mung-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/MungBeanDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/bamboo-bean",
    name: "bamboo-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/BambooBeanDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/butter-bean",
    name: "butter-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/ButterBeanDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/ground-nuts-bean",
    name: "ground-nuts-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/GroundNutsDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/lablab-bean",
    name: "lablab-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/LabLabBeanDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/red-bamboo-bean",
    name: "red-bamboo-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/RedBambooDetail.vue"),
    meta: {
      layout: "default"
    }
  },
  {
    path: "/product-detail/red-flat-bean",
    name: "red-flat-bean-detail",
    component: () =>
      import("@/modules/dashboard/productsDetail/RedFlatBeanDetail.vue"),
    meta: {
      layout: "default"
    }
  }
]

export default dashboardRoutes

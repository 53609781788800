<template>
  <div class="overflow-hidden">
    <div id="footer" class="py-4 px-4 lg:px-8 mx-0 bg-primary text-color">
      <div class="flex justify-content-between">
        <div class="col-12 lg:col-6 flex flex-column justify-content-around">
          <div class="col-12">
            <div class="flex align-items-center justify-content-start md:mb-0">
              <router-link to="/" class="layout-topbar-logo flex align-items-center">
                <img alt="Logo" class="layout-footer-logo" src="@/assets/images/MHT.svg" />
                <span class="inline-block text-color-secondary text-3xl text-900">Moe Htet Tun</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 flex flex-column align-items-start footer-contact">
            <!-- <div class="pb-3">
              <i class="pi pi-facebook px-2"></i>
              <i class="pi pi-twitter px-2"></i>
              <i class="pi pi-instagram px-2"></i>
              <i class="pi pi-youtube px-2"></i>
              <i class="pi pi-whatsapp px-2"></i>
            </div> -->

            <div class="flex flex-column align-items-start">
              <p class="mb-1 text-left">
                <strong>Address :</strong> Seik Ta Thu Kha St, Ywar Lal Yard, Mahar Aung Myay Township,
                Mandalay
              </p>
              <p class="mb-1 text-left"><strong>Whatsapp : </strong>+959402546908</p>
              <!-- <p class="mb-1 text-left"><strong>Call Now :</strong> 092016225</p> -->
              <p class="text-left"><strong>Mail To :</strong> htetlwin@moehtettun.com ,
                info@moehtettun.com</p>
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6 lg:flex flex-column hidden">
          <div class="flex justify-content-end">
            <ul class="list-contact flex">
              <li>
                <router-link to="/about-us">
                  <Button type="button" label="About Us" style="width: auto" class="p-button-text" />
                </router-link>
              </li>
              <li>
                <router-link to="/products-knowledge">
                  <Button type="button" label="Products" style="width: auto" class="p-button-text" />
                </router-link>
              </li>
              <li>
                <router-link to="/products-knowledge">
                  <Button type="button" label="Products Knowledge" style="width: auto" class="p-button-text" />
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us">
                  <Button type="button" label="Contact Us" style="width: auto" class="p-button-text" />
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-12 flex justify-content-end align-items-center">
            <div class="gmap_canvas">
              <iframe width="500" height="200" id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.2453030183906!2d96.0605837!3d21.963546500000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30cb1391808b0d21%3A0xfcd22fec0e08726f!2z4YCF4YCt4YCU4YC64YCc4YCZ4YCE4YC64YC44YCV4YCy4YCB4YC94YCy4YCF4YCA4YC6!5e0!3m2!1sen!2smm!4v1671901015718!5m2!1sen!2smm"
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-footer bg-primary">
      <i class="ri-copyright-line mx-2"></i><span>2022 Sein La Min Co.,ltd.</span>
      <span class="mx-2">All Rights Reserved</span>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
export default {
  name: "AppFooter",
  components: { Button },
}
</script>
<style>
.layout-footer {
  padding: 2rem;
}

.list-contact {
  list-style: none;
}

.list-contact .p-button-label {
  color: #295f2d;
}

@media screen and (max-width: 1215px) {
  .gmap_canvas iframe {
    width: 400px;
  }
}
</style>
